<template>
    <div class="touchBanner">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4_732" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_4_732)">
                <path d="M10.475 22.5C10.0084 22.5 9.57088 22.4 9.16255 22.2C8.75422 22 8.40838 21.7167 8.12505 21.35L2.67505 14.425L3.15005 13.925C3.48338 13.575 3.88338 13.3667 4.35005 13.3C4.81672 13.2333 5.25005 13.325 5.65005 13.575L7.50005 14.7V6.5C7.50005 6.21667 7.59588 5.97917 7.78755 5.7875C7.97922 5.59583 8.21672 5.5 8.50005 5.5C8.78338 5.5 9.02505 5.59583 9.22505 5.7875C9.42505 5.97917 9.52505 6.21667 9.52505 6.5V18.3L7.10005 16.8L9.70005 20.125C9.80005 20.2417 9.91672 20.3333 10.05 20.4C10.1834 20.4667 10.325 20.5 10.475 20.5H16C16.55 20.5 17.0209 20.3042 17.4125 19.9125C17.8042 19.5208 18 19.05 18 18.5V14.5C18 14.2167 17.9042 13.9792 17.7125 13.7875C17.5209 13.5958 17.2834 13.5 17 13.5H11.525V11.5H17C17.8334 11.5 18.5417 11.7917 19.125 12.375C19.7084 12.9583 20 13.6667 20 14.5V18.5C20 19.6 19.6084 20.5417 18.825 21.325C18.0417 22.1083 17.1 22.5 16 22.5H10.475ZM4.17505 9C3.95838 8.63333 3.79172 8.2375 3.67505 7.8125C3.55838 7.3875 3.50005 6.95 3.50005 6.5C3.50005 5.11667 3.98755 3.9375 4.96255 2.9625C5.93755 1.9875 7.11672 1.5 8.50005 1.5C9.88338 1.5 11.0625 1.9875 12.0375 2.9625C13.0125 3.9375 13.5 5.11667 13.5 6.5C13.5 6.95 13.4417 7.3875 13.325 7.8125C13.2084 8.2375 13.0417 8.63333 12.825 9L11.1 8C11.2334 7.76667 11.3334 7.52917 11.4 7.2875C11.4667 7.04583 11.5 6.78333 11.5 6.5C11.5 5.66667 11.2084 4.95833 10.625 4.375C10.0417 3.79167 9.33338 3.5 8.50005 3.5C7.66672 3.5 6.95838 3.79167 6.37505 4.375C5.79172 4.95833 5.50005 5.66667 5.50005 6.5C5.50005 6.78333 5.53338 7.04583 5.60005 7.2875C5.66672 7.52917 5.76672 7.76667 5.90005 8L4.17505 9Z" fill="#525250"/>
            </g>
        </svg>
        <p class="topMeta">Tap the image to see a new one</p>
    </div>
</template>

<script>
export default {

}
</script>