<template>
    <div class="ImageWrapper" @click="removeFirstImage">
        <ImageCard v-for="image in imgPaths" :key="image" :source="image" :data="false"/>
    </div>
</template>

<script>
import ImageCard from '@/components/ImageCard.vue'

export default {
    components: { Image, ImageCard },
    data() {
        return {
            imgPaths: []
        }
    },
    created() {
        this.fetchImages();
    },
    watch: {
        imgPaths(newVal) {
            if (newVal.length === 5) {
                this.fetchImages();
            }
        }
    },
    methods: {
        fetchImages() {
            fetch('https://api.thecatapi.com/v1/images/search?limit=10')
                .then(response => response.json())
                .then(data => {
                    this.imgPaths = [...this.imgPaths, ...data.map(image => image.url)];
                })
                .catch(error => {
                    console.error('Error fetching images:', error);
                });
        },
        removeFirstImage() {
            if (this.imgPaths.length > 0) {
                this.imgPaths = this.imgPaths.slice(1);
            }
        }
    }
}
</script>