<template>
    <Top />
    <ImageWrapper />
    <TouchBanner />
</template>

<script>
import Top from '@/components/Top.vue'
import ImageWrapper from '../components/ImageWrapper.vue';
import TouchBanner from '@/components/TouchBanner.vue'

export default {
    components: { Top, ImageWrapper, TouchBanner },
    created() {
        this.updateBodyHeight();
        window.addEventListener('resize', this.updateBodyHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateBodyHeight);
    },
    methods: {
        updateBodyHeight() {
            document.body.style.height = `${window.innerHeight}px`;
        }
    }
}
</script>